
import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Box, Card, FormControl,
  Grid, Icon, TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import ProductTreesDetailModal from './detail';
import ProductTreesTable from './table';
import { fetchProductTrees } from './fetchData';
import dynTheme from 'assets/dynTheme';

function ProductTreesView() {

  const [refresh, setRefresh] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const [orders, setOrders] = useState([]);
  const [fetchingOrders, setFetchingOrders] = useState(false);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingOrders(true);
    fetchProductTrees(
      sessionID,
    ).then((data) => {
      if (data != null) {
        setOrders(data.reverse());
      }
    }).finally(() => {
      setFetchingOrders(false);
    });
  }, [refresh]);

  const handleDetail = (id) => {
    setDetailId(id);
    setShowForm(true);
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setDetailId(null);
  }

  const handleSubmitForm = () => {
    setRefresh(!refresh);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Estruturas de Produtos
              </MDTypography>
            </Box>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <ProductTreesTable
                  data={orders}
                  isLoading={fetchingOrders}
                  onEdit={handleDetail}
                />
              </Grid>
            </Grid>
            <ProductTreesDetailModal
              id={detailId}
              open={showForm}
              onClose={handleCloseForm}
              onSubmit={handleSubmitForm}
            />
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default ProductTreesView;
