import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Box, Card, FormControl,
  Grid, InputLabel, TextField, createTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import LogProdDetailModal from './detail';
import ApontamentosOPTable from './table';
import { types, statuses, fetchProjects } from './fetchData';
import { useStatus } from 'hooks/useStatus';
import dynTheme from 'assets/dynTheme';

let debounceTimer;
function debounce(func, delay) {
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

function ProjectTsView() {

  const [showForm, setShowForm] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const [name, setName] = useState('');
  const [financialProject, setFinancialProject] = useState('');
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().startOf('year'));
  const [endDate, setEndDate] = useState(dayjs());

  const [projects, setProjects] = useState([]);
  const [fetchingProjects, setFetchingProjects] = useState(false);

  const { toggleStatus } = useStatus();

  const isManager = Cookies.get('logemployeerole') === 'Manager';

  useEffect(debounce(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingProjects(true);
    fetchProjects(
      sessionID, name, financialProject, status?.id, type?.id, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')
    ).then((data) => {
      if (data != null) {
        setProjects(data);
      }
    }).finally(() => {
      if (!debounceTimer) {
        setFetchingProjects(false);
      }
    });
  }, 1000), [name, financialProject, type, status, startDate, endDate]);

  const handleDetail = (id) => {
    if (!isManager) {
      toggleStatus('Atenção', 'Funcionalidade limitada por política organizacional', 'warning');
      return;
    }
    setDetailId(id);
    setShowForm(true);
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setDetailId(null);
  }

  const handleChangeName = (e) => {
    setName(e.currentTarget.value);
  }

  const handleChangeFinancialProject = (e) => {
    setFinancialProject(e.currentTarget.value);
  }

  const handleChangeType = (e, value) => {
    setType(value);
  }

  const handleChangeStatus = (e, value) => {
    setStatus(value);
  }

  const handleChangeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    }
  }

  const handleChangeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Gestão de Apontamentos de Projetos
              </MDTypography>
            </Box>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
                <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="nome" label="Nome" value={name}
                        onChange={handleChangeName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="financialProject" label="Projeto Financeiro"
                        value={financialProject} onChange={handleChangeFinancialProject}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="type" options={types}
                        value={type} onChange={handleChangeType}
                        renderInput={(params) => <TextField {...params} label="Tipo" />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="status" options={statuses}
                        value={status} onChange={handleChangeStatus}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-inicio" label="Data Início" onChange={handleChangeStartDate} value={startDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-final" label="Data Final" onChange={handleChangeEndDate} value={endDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0} md={1} />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <ApontamentosOPTable
                  data={projects}
                  isLoading={fetchingProjects}
                  onEdit={handleDetail}
                />
              </Grid>
            </Grid>
            <LogProdDetailModal
              id={detailId}
              open={showForm}
              onClose={handleCloseForm}
            />
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default ProjectTsView;
