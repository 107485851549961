import RequestPageIcon from '@mui/icons-material/RequestPage';
import { BarChart, PunchClock, ShoppingCart, TravelExplore } from "@mui/icons-material";

import SessionID from "layouts/SessionID";
import TimeSheet from "layouts/TimeSheet";
import Employee from "layouts/Employee";
import ProjectMg from "layouts/ProjectMg";
import ProductionOrder from "layouts/ProductionOrder";
import LogProd from "layouts/LogProd";
import LogMat from "layouts/LogMat";
import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import ProjectFin from "layouts/ProjectFin";
import PurchaseOrder from "layouts/PurchaseOrder";
import Product from "layouts/Product";
import PurchaseRequest from "layouts/PurchaseRequest";
import ProductTree from "layouts/ProductTree";
import ProjectPs from "layouts/ProjectPs";
import ProjectTs from "layouts/ProjectTs";
import InventoryTransferRequestView from 'layouts/InventoryTransferRequest';
import StockTransferView from 'layouts/StockTransfer';
import TourView from 'layouts/Tour';
import StockTransferValidationView from 'layouts/StockTransferValidation';
import InventoryGenExitView from 'layouts/InventoryGenExit';
import BIView from 'layouts/BI';




/*
import Department from "layouts/Department";
import ProfitCenter from "layouts/ProfitCenter";
import User from "layouts/User";
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignUp from "layouts/authentication/sign-up";
import DesignServices from '@mui/icons-material/DesignServices';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import SignOut from "layouts/authentication/sign-out";

import Resource from "layouts/Resource";
import Order from "layouts/Order";
import BusinessPartner from "layouts/BusinessPartner";

*/

const routes = [


  {
    type: "collapse",
    name: "Home",
    key: "Home",
    icon: <Icon fontSize="small">homeicon</Icon>,
    route: "/Home",
    component: <SessionID />,
  },
  {
    type: "collapse",
    name: "BI",
    key: "BI",
    icon: <Icon fontSize="small"><BarChart /></Icon>,
    route: "/BI",
    component: <BIView />,
  },
  {
    type: "title",
    title: "Apontamentos"
  },
  {
    type: "collapse",
    name: "Apontamentos Projeto",
    key: "Apontamentos",
    icon: <Icon fontSize="small">timer</Icon>,
    route: "/Apontamentos",
    component: <TimeSheet />,
  },

  {
    type: "collapse",
    name: "Apontamentos Fábrica ",
    key: "LogProd",
    icon: <Icon fontSize="small">alarm</Icon>,
    route: "/LogProd",
    component: <LogProd />,
  },

  {
    type: "collapse",
    name: "Consumo de Material",
    key: "LogMat",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/InventoryGenExits",
    component: <InventoryGenExitView />,
  },

  {
    type: "title",
    title: "Projetos",
  },
  {
    type: "collapse",
    name: "Gestão de Projetos",
    key: "GestaodeProjetos",
    icon: <Icon fontSize="small">psychology</Icon>,
    route: "/GestaodeProjetos",
    component: <ProjectMg />,
  },
  {
    type: "collapse",
    name: "Gestão de Compras",
    key: "GestaodeCompras",
    icon: <Icon fontSize="small"><ShoppingCart /></Icon>,
    route: "/GestaodeCompras",
    component: <ProjectPs />,
  },
  {
    type: "collapse",
    name: "Gestão de Apontamentos",
    key: "GestaodeApontamentos",
    icon: <Icon fontSize="small"><PunchClock /></Icon>,
    route: "/GestaodeApontamentos",
    component: <ProjectTs />,
  },
  {
    type: "title",
    title: "Almoxarifado",
  },
  {
    type: "collapse",
    name: "Picking",
    key: "InventoryTransferRequest",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/InventoryTransferRequest",
    component: <InventoryTransferRequestView />,
  },
  {
    type: "collapse",
    name: "Conferência",
    key: "StockTransferValidation",
    icon: <Icon fontSize="small">fact_check</Icon>,
    route: "/StockTransferValidation",
    component: <StockTransferValidationView />,
  },
  {
    type: "collapse",
    name: "Transferências",
    key: "StockTransfer",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/StockTransfer",
    component: <StockTransferView />,
  },

  {
    type: "title",
    title: "PCP",
  },

  {
    type: "collapse",
    name: "Ordens de Produção",
    key: "OrdensdeProducao",
    icon: <Icon fontSize="small">factory</Icon>,
    route: "/OrdensdeProducao",
    component: <ProductionOrder />,
  },

  /*{
    type: "collapse",
    name: "Projetos Contábeis",
    key: "ProjetosFin",
    icon: <Icon fontSize="small">walleticon</Icon>,
    route: "/ProjetosFin",
    component: <ProjectFin />,
  },*/

  {
    type: "collapse",
    name: "Pedidos de Compra",
    key: "PedidosdeCompra",
    icon: <Icon fontSize="small">shoppingcarticon</Icon>,
    route: "/PedidosdeCompra",
    component: <PurchaseOrder />,
  },

  {
    type: "collapse",
    name: "Solic. de Compra",
    key: "SolicdeCompra",
    icon: <RequestPageIcon fontSize="small"></RequestPageIcon>,
    route: "/SolicdeCompra",
    component: <PurchaseRequest />,
  },


  {
    type: "collapse",
    name: "Estrutura de Produto (EP)",
    key: "ProductTress",
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/ProductTree",
    component: <ProductTree />,
  },

  {
    type: "collapse",
    name: "Produtos",
    key: "Produtos",
    icon: <Icon fontSize="small">memory</Icon>,
    route: "/Produtos",
    component: <Product />,
  },

  {
    type: "title",
    title: "RH",
  },
  {
    type: "collapse",
    name: "Colaboradores",
    key: "Colaboradores",
    icon: <Icon fontSize="small">badgeicon</Icon>,
    route: "/Colaboradores",
    component: <Employee />,
  },
  {
    type: "divider",
  },
  {
    type: "collapse",
    name: "Tour Virtual",
    key: "Tour",
    icon: <Icon fontSize="small"><TravelExplore /></Icon>,
    route: "/Tour",
    component: <TourView />,
  },

  {
    type: "collapse",
    name: "Sair",
    key: "sign-in",
    icon: <Icon fontSize="medium">logout</Icon>,
    route: "/Authentication",
    component: <SignIn />,
    fixed: true,
  },
  /*
  {
    type: "collapse",
    name: "Usuários",
    key: "Usuarios",
    icon: <Icon fontSize="small">peopleicon</Icon>,
    route: "/Usuarios",
    component: <User />,
  },

  {
    type: "collapse",
    name: "Departamentos",
    key: "Departamentos",
    icon: <Icon fontSize="small">desk</Icon>,
    route: "/Departamentos",
    component: <Department />,
  },

  {
    type: "collapse",
    name: "Centros de Lucro",
    key: "CentrosdeLucro",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/CentrosdeLucro",
    component: <ProfitCenter />,
  },

  {
    type: "collapse",
    name: "Parceiros",
    key: "Parceiros",
    icon: <Icon fontSize="small">businessicon</Icon>,
    route: "/Parceiros",
    component: <BusinessPartner />,
  },

  {
    type: "collapse",
    name: "Pedidos de Venda",
    key: "PedidosdeVenda",
    icon: <Icon fontSize="small">sell</Icon>,
    route: "/PedidosdeVenda",
    component: <Order />,
  },




  {
    type: "collapse",
    name: "Recursos",
    key: "Recursos",
    icon: <Icon fontSize="small">engineeringicon</Icon>,
    route: "/Recursos",
    component: <Resource />,
  },





  */

];
export default routes;



/*




 // {
 //   type: "collapse",
 //   name: "Sign Out ⁽ᴮᵉᵗᵃ⁾",
 //   key: "sign-out",
 //   icon: <Icon fontSize="small">logout</Icon>,
 //   route: "/authentication/sign-out",
 //   // component: <SignOut />,
 // },

 {
   type: "collapse",
   name: "Dashboard",
   key: "dashboard",
   icon: <Icon fontSize="small">dashboard</Icon>,
   route: "/dashboard",
   component: <Dashboard />,
 },


 {
   type: "collapse",
   name: "Tables",
   key: "tables",
   icon: <Icon fontSize="small">table_view</Icon>,
   route: "/tables",
   component: <Tables />,
 },
 {
   type: "collapse",
   name: "Billing",
   key: "billing",
   icon: <Icon fontSize="small">receipt_long</Icon>,
   route: "/billing",
   component: <Billing />,
 },

 {
   type: "collapse",
   name: "Notifications",
   key: "notifications",
   icon: <Icon fontSize="small">notifications</Icon>,
   route: "/notifications",
   component: <Notifications />,
 },
 {
   type: "collapse",
   name: "Profile",
   key: "profile",
   icon: <Icon fontSize="small">person</Icon>,
   route: "/profile",
   component: <Profile />,


 },
 {
   type: "collapse",
   name: "Sign Up",
   key: "sign-up",
   icon: <Icon fontSize="small">assignment</Icon>,
   route: "/authentication/sign-up",
   component: <SignUp />,
 },

*/


