import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog,
  DialogActions,
  DialogContent, Divider, FormControlLabel, Grid, IconButton, InputLabel,
  TextField, Toolbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel, Send } from '@mui/icons-material';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import { fetchStockTransfer, fetchStockTransferLines, patchStockTransfer } from './fetchData';
import TransfersTable from './subtable';
import TransferSubForm from './subform';

const StockTransferDetailModal = ({ open, onClose, onSubmit, id }) => {

  const [docNum, setDocNum] = useState('');
  const [fromWarehouse, setFromWarehouse] = useState('');
  const [toWarehouse, setToWarehouse] = useState('');
  const [date, setDate] = useState('');
  const [comments, setComments] = useState('');
  const [receiver, setReceiver] = useState('');
  const [receivedDate, setReceivedDate] = useState('');

  const [transfer, setTransfer] = useState({});
  const [lines, setLines] = useState([]);
  const [validateds, setValidateds] = useState([]);

  const { toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);

  const [fetchingTransfer, setFetchingTransfer] = useState(false);
  const [fetchingLines, setFetchingLines] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setRefresh(false);
    if (id && !fetchingTransfer) {
      const sessionID = Cookies.get('sessionID');
      setFetchingTransfer(true);
      fetchStockTransfer(sessionID, id).then((data) => {
        if (data != null) {
          setTransfer(data);
          setDocNum(data['DocNum']);
          setFromWarehouse(data['FromWarehouse']);
          setToWarehouse(data['ToWarehouse']);
          setDate(dayjs(data['DocDate']).format('DD/MM/YYYY'));
          setComments(data['Comments']);
          setReceiver(data['receiver']);
          setReceivedDate(data['U_EASY_DtRec'] ? dayjs(data['U_EASY_DtRec']).format('DD/MM/YYYY') : '');
        }
      }).finally(() => setFetchingTransfer(false));
    }
    if (id && !fetchingLines) {
      const sessionID = Cookies.get('sessionID');
      setFetchingLines(true);
      fetchStockTransferLines(sessionID, id).then((data) => {
        if (data != null) {
          setLines(data);
        }
      }).finally(() => setFetchingLines(false));
    }
  }, [id, refresh]);

  useEffect(() => {
    const v = [];
    lines.forEach((line) => {
      if (line['U_TextoLivre4'] != null) {
        v.push(line);
      }
    });
  }, [lines]);

  const handleRemovePicking = (index) => {
    const line = validateds[index];
    setValidateds(validateds.filter((p) => p['BaseLine'] !== line['BaseLine']));
  }

  const handleReset = () => {
    setDocNum('');
    setFromWarehouse('');
    setToWarehouse('');
    setDate('');
    setComments('');
    setReceiver('');
    setReceivedDate('');
    setLines([]);
    setValidateds([]);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleEditForm = (index) => {
    setEditIndex(index);
    setShowEdit(true);
  }

  const handleCloseForm = () => {
    setEditIndex(null);
    setShowEdit(false);
  }

  const handleFormSubmit = (line) => {
    setValidateds([...validateds, line]);
    setEditIndex(null);
    setShowEdit(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving('Salvando');
    const sessionID = Cookies.get('sessionID');
    const employeeID = Cookies.get('logemployeeID');
    const v = validateds.filter((line) => line['id'] === undefined);
    v.forEach((line) => {
      const l = lines.find((l) => l['LineNum'] === line['LineNum']);
      if (l) {
        line['U_TextoLivre4'] = parseFloat(line['U_TextoLivre4']) + parseFloat(l['U_TextoLivre4']);
      }
    });
    const data = {
      "U_RecebidoPor": employeeID,
      "U_EASY_DtRec": new Date().toISOString(),
      "StockTransferLines": v,
    };
    setSaving('Salvando');
    setShowEdit(false);
    patchStockTransfer(sessionID, id, data)
      .then(() => {
          toggleStatus('Sucesso', 'Transferência conferida com sucesso', 'success');
          onSubmit();
          handleReset();
          onClose();
      })
      .catch((error) => {
        toggleStatus('Erro ao conferir transferência', error, 'error');
      })
      .finally(() => setSaving(false));
  }

  const highlightPickingCallback = (line) => {
    for (let i = 0; i < validateds.length; i++) {
      const p = validateds[i];
      if (p['LineNum'] === line['LineNum']) {
        if (p['U_TextoLivre4'] >= line['Quantity']) {
          return 'linear-gradient(#0F02, #0F02)';
        }
        return 'linear-gradient(#FF02, #FF02)'
      }
    }
    return 'inherit';
  };

  const highlightTransferCallback = (picking) => {
    if (picking['id'] === undefined) {
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (picking['LineNum'] === line['LineNum']) {
          if (picking['U_TextoLivre4'] >= line['Quantity']) {
            return 'linear-gradient(#0F02, #0F02)';
          }
          return 'linear-gradient(#FF02, #FF02)';
        }
      }
    }
    return 'inherit';
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Conferência de Transferência
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="ID" value={docNum} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Do Depósito" value={fromWarehouse} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Para Depósito" value={toWarehouse} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Data" value={date} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField fullWidth label="Comentários" value={comments} disabled variant="standard" />
          </Grid>
          <Grid item xs={0} sm={3} />
          <Grid item xs={12} sm={4}>
            <TextField fullWidth label="Conferido por" value={receiver} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Data Conferência" value={receivedDate} disabled variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6" component="div" mt={2}>Materiais</Typography>
            <TransfersTable
              lines={lines}
              icon={<Send />}
              onClick={handleEditForm}
              showMU
              highlightCallback={highlightPickingCallback}
              isLoading={fetchingLines}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="div" mt={2}>Conferidos</Typography>
            <TransfersTable
              lines={[...validateds, ...lines.filter((line) => line['U_TextoLivre4'] != null)]}
              icon={<Cancel />}
              onClick={handleRemovePicking}
              enabledCallback={(line) => line['id'] === undefined}
              highlightCallback={highlightTransferCallback}
              isLoading={fetchingLines}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" onClick={() => setValidateds([])} variant="text">Limpar</Button>
        <Button color="warning" disabled={validateds.length == 0} onClick={handleSubmit} variant="contained">Salvar</Button>
      </DialogActions>
      <TransferSubForm
        open={showEdit}
        onClose={handleCloseForm}
        onSubmit={handleFormSubmit}
        line={lines[editIndex]}
      />
      <Backdrop open={saving} sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingTransfer} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

StockTransferDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

export default StockTransferDetailModal;
